import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import BlockContent from "../block-content"
import { getBlogUrl } from "../../lib/helpers"
import styles from "../../styles/herosection.module.css"

function HeroSection(props) {
  var contrast
  switch (props.data.category.favoriteColor.hex) {
    case "#ffc3ad":
      contrast = "#FBFFA0"
      break
    case "#fbffa0":
      contrast = "#FFD8D8"
      break
    case "#9bc2e5":
      contrast = "#FFE1E1"
      break
    case "#ffe1e1":
      contrast = "#A3D6C7"
      break
    default:
      contrast = "white"
  }
  const Container = styled.div`
    background-color: ${props => props.color};
  `
  const Letter = styled.div`
    color: ${props => props.contrastColor};
  `
  const sources = [
    props.data.mobileImage.asset.fluid,
    {
      ...props.data.desktopImage.asset.fluid,
      media: `(min-width: 1100px)`
    }
  ]
  return (
    <Container color={props.data.category.favoriteColor.hex} className={styles.herocontainer}>
      <div className={styles.image}>
        <Img fluid={sources} />
      </div>
      <div className={styles.txt}>
        <Link
          to={`/${getBlogUrl(
            props.data.parenttag.slug.current,
            props.data.slug.current,
            props.data.articleTier
          )}`}
          className={styles.linkstyle}
        >
          <h2 className={styles.title}>{props.data.title}</h2>
          <div className={styles.authordate}>
            <span className={styles.uppercase}>{props.data.category.title}</span> | von{" "}
            <span className={styles.uppercase}>{props.data.authors.name}</span>,{" "}
            {props.data.publishedAt}
          </div>
          <BlockContent blocks={props.data._rawExcerpt} className="articleLayout" />
          <div className={styles.readmore}>
            <p>
              WEITERLESEN <span className={styles.arrow}>→</span>
            </p>
          </div>
        </Link>
      </div>

      <Letter contrastColor={contrast} className={styles.letter}>
        {props.data.title.substring(0, 1)}
      </Letter>
      <div className={styles.placeholder}>&nbsp;</div>
    </Container>
  )
}

export default HeroSection
