import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import BlockContent from "../block-content"
import { getBlogUrl } from "../../lib/helpers"
import styles from "../../styles/topnews.module.css"

const Category = styled.div`
  background-color: ${props => props.color};
  text-transform: uppercase;
  width: max-content;
  border: 1px solid ${props => props.color};
  border-radius: 20px;
  margin-bottom: 20px;
`
const SmallCategory = styled.div`
  background-color: ${props => props.color};
  text-transform: uppercase;
  width: max-content;
  border: 1px solid ${props => props.color};
  border-radius: 20px;
  margin-bottom: 10px;
`
const SmallCategoryname = styled.p`
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2.5px;
  padding-bottom: 2px;
  margin: 0;
  font-size: 12px;
`
const Categoryname = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 3px;
  margin: 0;
  font-size: 80%;
`

const Headline = styled.h2`
  :hover {
    text-decoration: underline;
    text-decoration-color: ${props => props.color};
  }
`

function TopNews(props) {
  const sources = [
    props.data.topItemsMain.mobileImage.asset.fluid,
    {
      ...props.data.topItemsMain.desktopImage.asset.fluid,
      media: `(min-width: 1100px)`
    }
  ]
  return (
    <div className={styles.topnewscontainer}>
      <div className={styles.meistgelesen}>
        <h2 className={styles.title}>Meistgelesene Artikel</h2>
      </div>

      <div className={styles.mainitem}>
        <Link
          to={`/${getBlogUrl(
            props.data.topItemsMain.parenttag.slug.current,
            props.data.topItemsMain.slug.current,
            props.data.topItemsMain.articleTier
          )}`}
          className={styles.linkstyle}
        >
          <Img className={styles.mainitemimg} fluid={sources} />
          <Category color={props.data.topItemsMain.category.favoriteColor.hex}>
            <Categoryname>{props.data.topItemsMain.category.title}</Categoryname>
          </Category>

          <Headline
            color={props.data.topItemsMain.category.favoriteColor.hex}
            className={styles.title}
          >
            {props.data.topItemsMain.title}
          </Headline>

          <BlockContent blocks={props.data.topItemsMain._rawExcerpt} className="articleLayout" />
          <div className={styles.readmore}>
            <p>
              von <span className={styles.uppercase}>{props.data.topItemsMain.authors.name}</span>
              <span className={styles.arrow}>WEITERLESEN →</span>
            </p>
          </div>
        </Link>
      </div>

      <div className={styles.previews}>
        {props.data.topItems.map(item => (
          <Link
            key={item.mobileImage.asset._id}
            to={`/${getBlogUrl(item.parenttag.slug.current, item.slug.current, item.articleTier)}`}
            className={styles.linkstyle}
          >
            <div className={styles.singleitem}>
              <div className={styles.previewImg}>
                <Img fixed={item.mobileImage.asset.fixed} />
              </div>
              <div className={styles.texts}>
                <SmallCategory className={styles.smallcat} color={item.category.favoriteColor.hex}>
                  <SmallCategoryname>{item.category.title}</SmallCategoryname>
                </SmallCategory>
                <Headline color={item.category.favoriteColor.hex} className={styles.smalltitle}>
                  {item.title}
                </Headline>
                <p className={styles.smallauthor}>
                  von {item.authors.name}
                  <span className={styles.weiter}>WEITERLESEN →</span>
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default TopNews
