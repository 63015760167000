import React from "react"
import { graphql } from "gatsby"
import BlogPostPreviewGrid from "../components/main/blog-post-preview-grid"
import BlogPostPreviewGridR from "../components/main/blog-post-preview-grid-r"
import GraphQLErrorList from "../components/main/graphql-error-list"
import SEO from "../components/main/seo"
import HeroSection from "../components/main/herosection"
import TopNews from "../components/main/topnews"
import Layout from "../containers/layout"
import { mapEdgesToNodes, getPostByCategory } from "../lib/helpers"

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      title
      description
      keywords
    }
    frontpage: sanityFrontpage(_id: { eq: "frontPage" }) {
      hero {
        parenttag {
          title
          slug {
            current
          }
        }
        category {
          title
          favoriteColor {
            hex
          }
        }
        title
        authors {
          name
        }
        publishedAt(formatString: "DD.MM.YYYY")
        slug {
          current
        }
        articleTier
        _rawExcerpt
        desktopImage: mainImage {
          asset {
            fluid(maxHeight: 900) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
            _id
          }
        }
        mobileImage: mainImage {
          asset {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
            _id
          }
        }
      }
      topItems {
        parenttag {
          parentTagColor {
            hex
          }
          title
          slug {
            current
          }
        }
        category {
          title
          favoriteColor {
            hex
          }
        }
        title
        authors {
          name
        }
        publishedAt(formatString: "DD.MM.YYYY")
        slug {
          current
        }
        articleTier
        _rawExcerpt
        mobileImage: mainImage {
          asset {
            fixed(width: 150, height: 150) {
              aspectRatio
              base64
              height
              src
              srcSet
              srcSetWebp
              srcWebp
              width
            }
            _id
          }
        }
      }
      topItemsMain {
        parenttag {
          parentTagColor {
            hex
          }
          title
          slug {
            current
          }
        }
        category {
          title
          favoriteColor {
            hex
          }
        }
        title
        authors {
          name
        }
        publishedAt(formatString: "DD.MM.YYYY")
        slug {
          current
        }
        articleTier
        _rawExcerpt
        mobileImage: mainImage {
          asset {
            fluid(maxWidth: 350) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
            _id
          }
        }
        desktopImage: mainImage {
          asset {
            fluid(maxHeight: 300) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
            _id
          }
        }
      }
    }

    cat: allSanityPost(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          articleTier
          parenttag {
            slug {
              current
            }
          }
          id
          title
          category {
            title
            catSlug
            favoriteColor {
              hex
            }
          }
          slug {
            current
          }
          authors {
            name
          }
          publishedAt
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
              fluid(maxWidth: 600) {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
            alt
          }
          authors {
            name
          }
          _rawAuthors(resolveReferences: { maxDepth: 2 })
          category {
            title
            favoriteColor {
              hex
            }
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const { site, frontpage, cat } = data
  const allCatPost = mapEdgesToNodes(cat).filter(item => item.category !== null)

  const postCat1 = getPostByCategory(allCatPost, "lifestyle-schlaf")
  const postCat2 = getPostByCategory(allCatPost, "Selbsttests-Hilfe")
  const postCat3 = getPostByCategory(allCatPost, "schlafstoerungen")
  const postCat4 = getPostByCategory(allCatPost, "schlafmittel")

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />

      <div className="frontpagecontainer">
        <HeroSection data={frontpage.hero} />
        <TopNews data={frontpage} />
        <BlogPostPreviewGrid nodes={postCat1} />
        <BlogPostPreviewGridR nodes={postCat2} />
        <BlogPostPreviewGrid nodes={postCat3} />
        <BlogPostPreviewGridR nodes={postCat4} />
      </div>
    </Layout>
  )
}

export default IndexPage
