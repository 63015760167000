import React from "react"
import Slider from "react-slick"
import BigPostPreview from "./big-post-preview"
import BlogPostPreview from "./blog-post-preview"
import styles from "../../styles/blog-post-preview-grid-r.module.css"
import RightArrowLogo from "../../assets/right-arrow.svg"

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      <div className={className} style={{ ...style, display: "none" }} />
      <img
        src={RightArrowLogo}
        alt="arrow_right"
        style={{ width: "38px", height: "48px", position: "absolute", top: "25%", right: "-2.5%" }}
      />
    </div>
  )
}

const PrevArrow = props => <div style={{ ...props.style, display: "none" }} />

function BlogPostPreviewGridR(props) {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    accessibility: true,
    className: "innerslide"
  }
  var mobilesettings = {
    dots: false,
    infinite: true,
    centerMode: true,
    centerPadding: "15%",
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    accessibility: true,
    className: "innerslide"
  }

  var categorybg = {
    background: ` ${props.nodes[0].category.favoriteColor.hex} `
  }

  return (
    <div className={`${styles.colorelement} previewbox`}>
      <div className={styles.background} style={categorybg} />
      <div className={styles.nobackground} />
      <div className={styles.background2} style={categorybg} />
      <div className={styles.nobackground2} />
      <div className={styles.root}>
        <div className={styles.catname}>
          <p className={styles.catnametext}>{props.nodes[0].category.title}</p>
        </div>
        <div className={styles.innergrid}>
          <div className={styles.titlearea}>
            <p>{props.nodes[0].category.title}</p>
          </div>
          <div className={styles.spacerl} />
          <div className={styles.inner}>
            <div className={styles.bigpreview}>
              <BigPostPreview {...props.nodes[0]} />
            </div>
            <div className={styles.slider}>
              <Slider {...settings}>
                {props.nodes &&
                  props.nodes.slice(1).map(node => (
                    <div key={node.id}>
                      <BlogPostPreview {...node} />
                    </div>
                  ))}
              </Slider>
            </div>
            <div className={styles.slidermobile}>
              <Slider {...mobilesettings}>
                {props.nodes &&
                  props.nodes.slice(1).map(node => (
                    <div key={node.id}>
                      <BlogPostPreview {...node} />
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
          <div className={styles.spacerr} />
        </div>
      </div>
    </div>
  )
}

BlogPostPreviewGridR.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: ""
}

export default BlogPostPreviewGridR
